.row {
    display: flex;
    align-items: center;
    justify-content: center;
}

.notes {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
}

.notekey {
    background-color: red;
}

.whitekey {
    position: relative;
    height: 30px;
    border: solid 1px transparent;
    margin-top: 2px;
    padding-left: 10px;
    display: flex;
    flex: 1;
    flex-direction: row;
}

.notesPlayed {
    position: relative;
    height: calc(100% - 4px);
    width: calc(100% - 30px);
    margin-left: 30px;
}

.notesPlayedInner {
    width: 100%;
    height: 100%;
    position: relative;
}

.whitekeyname {
    width: 100px;
    padding-left: 4px;
}

.timeKeeper {}

.chordProgressionStrips:nth-child(2n) {
    background-color: #FF00001a;
}

.chordProgressionStrips:nth-child(2n+1) {
    background-color: #0000FF1a;
}

.timeStrips:first-child {
    border-right: solid 1px black;
}
.timeStrips {
    border-left: solid 1px black;
}

.timeStrips:nth-child(2n) {
    background-color: #66666666;
}

.timeStrips:nth-child(2n+1) {
    background-color: #11111133;
}