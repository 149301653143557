@import url(https://fonts.googleapis.com/css?family=Lobster);
@import url(https://fonts.googleapis.com/css2?family=Paytone+One&display=swap);


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MidiNotes_row__3_70r {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.MidiNotes_notes__kmGcr {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    width: 100%;
}

.MidiNotes_notekey__2DVd3 {
    background-color: red;
}

.MidiNotes_whitekey__rXAtp {
    position: relative;
    height: 30px;
    border: solid 1px transparent;
    margin-top: 2px;
    padding-left: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.MidiNotes_notesPlayed__1rsPF {
    position: relative;
    height: calc(100% - 4px);
    width: calc(100% - 30px);
    margin-left: 30px;
}

.MidiNotes_notesPlayedInner__1qQSV {
    width: 100%;
    height: 100%;
    position: relative;
}

.MidiNotes_whitekeyname__12jSO {
    width: 100px;
    padding-left: 4px;
}

.MidiNotes_timeKeeper__3hYko {}

.MidiNotes_chordProgressionStrips__1JDz0:nth-child(2n) {
    background-color: #FF00001a;
}

.MidiNotes_chordProgressionStrips__1JDz0:nth-child(2n+1) {
    background-color: #0000FF1a;
}

.MidiNotes_timeStrips__2kdKO:first-child {
    border-right: solid 1px black;
}
.MidiNotes_timeStrips__2kdKO {
    border-left: solid 1px black;
}

.MidiNotes_timeStrips__2kdKO:nth-child(2n) {
    background-color: #66666666;
}

.MidiNotes_timeStrips__2kdKO:nth-child(2n+1) {
    background-color: #11111133;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-float infinite 3s ease-in-out;
            animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}
@-webkit-keyframes App-logo-float {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@keyframes App-logo-float {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

